import React from "react";
import CopyLink from "assets/copu_link_icon.svg";
import { Tooltip } from "react-tooltip";

const AiWordCounterOutput = ({
  detectedLanguage,
  error,
  shareresult,
  tooltipText,
}) => {
  return (
    <div className="mt-4 rounded-lg bg-white w-full px-4 py-6 shadow-lg">
      <div className="text-[#424242] text-lg font-opensans flex justify-between gap-6">
        <div>
          <p className="font-bold">{detectedLanguage.total_words}</p>
          <p className=" text-[#858585] text-sm font-semibold">words</p>
        </div>
        <div>
          <p className="font-bold">{detectedLanguage.total_characters}</p>
          <p className="text-[#858585] text-sm font-semibold">Characters</p>
        </div>
        <div>
          <p className="font-bold">{detectedLanguage.total_sentences}</p>
          <p className="text-[#858585] text-sm font-semibold">Sentences</p>
        </div>
        <div>
          <p className="font-bold">{detectedLanguage.total_paragraphs}</p>
          <p className="text-[#858585] text-sm font-semibold">Paragraph</p>
        </div>
      </div>
      <hr className="my-3 border border-[#C7C7C7]" />
      <div className=" flex justify-between items-center">
        {detectedLanguage?.languages ? (
          <div className="flex flex-col gap-2 mt-4">
            {detectedLanguage?.languages.map((item, i) => (
              <div key={i} className="flex gap-2 items-center">
                <span className="text-[#575757] text-sm font-bold leading-6 font-opensans">
                  {item.name}
                </span>
                <span className="text-[#575757] text-sm font-normal leading-6 font-opensans">
                  {item.word_count} words
                </span>
                <span className="text-[#575757] text-sm font-normal leading-6 font-opensans">
                  {item.character_count} Characters
                </span>
              </div>
            ))}
          </div>
        ) : (
          <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
            {error}
          </span>
        )}
        {shareresult && (
          <button
            className="flex items-center gap-2 mt-4"
            data-tooltip-id="linkcopy-tooltip"
            data-tooltip-content={tooltipText}
            onClick={shareresult}
          >
            <img src={CopyLink} alt="/copyicon" />

            <span className="text-sm font-opensans text-[#5B93FF] font-semibold">
              Copy link
            </span>
          </button>
        )}
      </div>
      <Tooltip
        id="linkcopy-tooltip"
        place="top"
        multiline={true}
        className="z-50"
      />
    </div>
  );
};

export default AiWordCounterOutput;
