import React, { useEffect, useState } from "react";
import Loader from "components/loader";
import {  navigate } from "gatsby";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { useLocation } from "@reach/router";
import { endpoints } from "utils/endpoints";
import CleanButton from "assets/cleanBtn.svg";
import Layout from "components/layout";
import SEO from "components/seo";
import { direction } from "utils/direction";
import AiWordCounterOutput from "components/commontools/AiWordCounterOutput";

const Result = () => {
  const [toggleTool, setToggleTool] = useState(false);
  const [summarizedText, setSummarizedText] = useState("");
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [shareId, setShareId] = useState("");
  const [error, setError] = useState(false);
  const [inputText, setInputText] = useState("");


  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = shareId ? shareId : UpdatreshareId;
  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {

      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        const { user_text: sourceText,source_language_code:sourceCode} = response?.meta;
        setInputLang(sourceCode)
        setDetectedLanguage(response.result);
        setWordCount(
          sourceText
            ? sourceText.split(/\s+/).filter((word) => word.length > 0).length
            : 0
        );
        // reset({ message: sourceText });
        setInputText(sourceText);

        let finalText = "";
        response.data.language_probability.forEach((k) => {
          const [key] = Object.entries(k);
          finalText += ` Most Likely ${key[0]} - ${Math.ceil(key[1] * 100)}%. `;
          setSummarizedText(finalText);
        });

        setToggleTool(true);
     
        setWordCount(0);
        window.scrollTo(0, 0);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };
    fatchAnalysis();
  }, [displayShareId]);


  const handleTextChange = (e) => {
    const text = e.target.value;
    setInputText(text);
  };
  useEffect(() => {
    const words = inputText.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
    // setDetectedLanguage("");
  }, [inputText]);

  if (loading)
    return (

        <div className="grid place-content-center h-[calc(100vh-150px)]">
          <Loader />
        </div>
   
    );
  return (
    <Layout>
     <SEO
    title="Free AI Word Counter"
    description="Effortlessly analyze multilingual text with the Tomedes Word Count Tool. Detect languages, count words and characters, and receive precise results tailored for each language."
    slug="/tools/word-counter"
  />
    <div className="bg-[#F5F5F5] ">
      <div className="w-full  py-7 ">
        <ToolsBreadcrumb />
      </div>

      <div className="py-12 lg:py-24 max-w-7xl mx-auto px-4 grid lg:grid-cols-2 gap-12">
        <div>
          <h1 className="text-[#5B93FF] mt-0 lg:mt-4 text-center md:text-start text-base font-primary font-semibold leading-6 mb-2.5">
          Word Counter
          </h1>
          <h2 className="text-[#0A2641] text-center md:text-start font-bold text-[28px] lg:text-[48px] leading-[48px] lg:leading-[56px] font-primary">
          Stay Within Character Limits with Precision and Ease
          </h2>
          <p className="hidden md:block text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4">
          The Tomedes Word and Character Count Tool makes managing multilingual text effortless. Whether you're crafting content for social media, preparing documents for translation, or ensuring text fits design constraints, this tool provides precise word and character counts tailored to each language's unique rules. Save time, maintain accuracy, and streamline your content workflow with ease.
          </p>
        </div>
        <div>
          <form
            action=""
            className="relative shadow-lg rounded-lg bg-white"
            // onSubmit={handleSubmit(onSubmit)}
          >
            <textarea
              placeholder="Type your text here"
              name="message"
              id="message"
              dir={direction(inputLang)}
              className={`anonymize_tool ${direction(inputLang) === "rtl" ? "pl-8 pr-5" : "pl-5 pr-8" } py-5 min-h-[360px] placeholder-[#B4B4B4] text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block w-full`}
              onChange={handleTextChange}
              value={inputText}
              readOnly
            ></textarea>

            {wordCount > 0 && (
              <p
                className={`absolute top-[14px]  w-max cursor-pointer ${direction(inputLang) === "rtl" ? "left-[10px]" : "right-[16px]" }`}
                onClick={() => navigate(`/tools/word-counter`)}
              >
                <img src={CleanButton} alt="clean-button" />
              </p>
            )}

          </form>
          <AiWordCounterOutput detectedLanguage={detectedLanguage} error={error}/>

          <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
          Stay Within Character Limits with Precision and Ease
          </p>
        </div>
        <p className="block md:hidden text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4">
        The Tomedes Word and Character Count Tool makes managing multilingual text effortless. Whether you're crafting content for social media, preparing documents for translation, or ensuring text fits design constraints, this tool provides precise word and character counts tailored to each language's unique rules. Save time, maintain accuracy, and streamline your content workflow with ease.
        </p>
      </div>
    </div>
    </Layout>
  );
};

export default Result;
